<template>
  <v-dialog
    v-model="showing"
    max-width="600px"
    content-class="languages-wrapper"
  >
    <v-card>
      <v-card-title>
        <div class="d-flex align-center">
          <div class="headline">
            KEY OVERVIEW
          </div>
          <v-icon class="cursor-pointer" @click="$emit('cancel', true)"
            >fal fa-times
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="mt-5">
          <div>
            <span class="form-label">
              Key
            </span>
            <v-text-field
              v-model="selectedKey.translationKey.key"
              name="Key"
              readonly
              outlined
            >
              <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      @click="copyCode(selectedKey.translationKey.key)"
                      >fal fa-copy</v-icon
                    >
                  </template>
                  <span>{{ copyText }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </div>
          <div>
            <span class="form-label">
              Code
            </span>
            <v-text-field
              :value="`{{ $t('${selectedKey.translationKey.key}') }}`"
              name="Code"
              readonly
              outlined
            >
              <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      @click="
                        copyCode(
                          `{{ $t('${selectedKey.translationKey.key}') }}`
                        )
                      "
                      >fal fa-copy</v-icon
                    >
                  </template>
                  <span>{{ copyText }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </div>
          <div class="mt-5">
            <span class="form-label">
              Areas
            </span>
            <v-chip-group column>
              <v-chip
                v-for="area in selectedKey.translationKey.translation_areas"
                :key="area.uuid"
                color="secondary"
                text-color="primary"
                class="chip-wrap"
              >
                {{ area.name }}
              </v-chip>
            </v-chip-group>
          </div>
          <div
            v-if="selectedKey.translationKey.translations.length"
            class="mt-5"
          >
            <div>Translations</div>
            <div
              v-for="translation in selectedKey.translationKey.translations"
              :key="translation.uuid"
            >
              <span class="form-label">
                <country-flag
                  :country="
                    translation.language.short_name === 'en'
                      ? 'us'
                      : translation.language.short_name
                  "
                  size="small"
                />
                {{ translation.language.name }}
              </span>
              <v-text-field
                v-model="translation.value"
                class="mt-5"
                readonly
              ></v-text-field>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import copy_to_clipboard from "@/helpers/mixins/copy_to_clipboard";

export default {
  mixins: [copy_to_clipboard],

  props: {
    value: {
      required: true
    },
    selectedKey: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    copyText: "Copy",
    loading: false
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
