<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Translation Page</div>
          <div class="subtitle-1 gretxt--text">
            Little description of translation page.
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on">fal fa-info-circle</v-icon>
              </template>
              <span>Info</span>
            </v-tooltip>
            <div class="d-flex justify-start p-0 label-text ">
              {{ translations.length }} Keys found out of {{ total }}
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="text-right mb-3">
          <v-btn
            outlined
            color="primary"
            @click="overviewLanguagesDialog = true"
            class="mr-5"
            >see languages</v-btn
          >
          <v-btn color="primary" @click="addNewKeyDialog = true">
            add key</v-btn
          >
        </div>
      </v-card-title>
      <v-card-actions>
        <translation-filters
          :languages="languages"
          :translationAreas="translationAreas"
          :filters="filters"
          @save="saveFilters($event)"
        />
      </v-card-actions>

      <v-card-text>
        <translations-data-table
          :translationAreas="translationAreas"
          :items="translations"
          :total="totalPages"
          :lang="lang"
          :page="filters.page"
          :defaultLanguageId="defaultLanguageId"
          @changePage="getAllTranslations($event)"
        />
      </v-card-text>
    </v-card>
    <languages-overview
      v-if="overviewLanguagesDialog"
      v-model="overviewLanguagesDialog"
      :languages="languages"
      @change="saveFilters($event)"
      @success="getAllLanguages()"
      @cancel="overviewLanguagesDialog = false"
    />
    <add-new-key
      v-if="addNewKeyDialog"
      :translationAreas="translationAreas"
      :languages="languages"
      v-model="addNewKeyDialog"
      @success="getAllTranslations(), (addNewKeyDialog = false)"
      @cancel="addNewKeyDialog = false"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LanguagesOverview from "@/components/moderator/dialogs/LanguagesOverview";
import AddNewKey from "@/components/moderator/dialogs/AddNewKey";
import TranslationsDataTable from "@/components/moderator/data-tables/TranslationsDataTable";
import TranslationFilters from "@/components/common/filters/TranslationFilters";
import search_filters_url from "@/helpers/mixins/search_filters_url";

export default {
  components: {
    LanguagesOverview,
    AddNewKey,
    TranslationsDataTable,
    TranslationFilters
  },
  mixins: [search_filters_url],

  data: () => ({
    overviewLanguagesDialog: false,
    addNewKeyDialog: false,
    translations: [],
    translationAreas: [],
    filters: {
      untranslated_only: false
    },
    defaultLanguageId: null,
    languages: [],
    limit: 5,
    totalPages: null,
    total: null,
    lang: null,
    loading: false
  }),

  methods: {
    ...mapActions("translations", [
      "getTranslations",
      "getLanguages",
      "getTranslationAreas"
    ]),
    getAllTranslations(page = 1) {
      this.loading = false;
      this.filters.page = page;
      let filters = this.cloneDeep(this.filters);
      if (!filters.search) {
        delete filters["search"];
      }
      if (filters.translation_areas && !filters.translation_areas.length) {
        delete filters["translation_areas"];
      }
      const params = {
        ...filters,
        limit: this.limit
      };
      this.setUrlSearchFilters(filters);
      this.lang = this.languages.filter(
        lang => lang.uuid === this.filters.language_uuid
      )[0];
      this.getTranslations(params).then(translations => {
        this.translations = translations.response;
        this.totalPages = translations.lastPage;
        this.total = translations.total;
        this.loading = true;
      }),
        () => {
          this.setSnackError("Noget gik galt");
        };
    },
    saveFilters(event) {
      this.filters = event;
      this.getAllTranslations(1);
    },
    getAreas() {
      this.getTranslationAreas().then(areas => {
        this.translationAreas = areas;
      }),
        () => {
          this.setSnackError("Noget gik galt");
        };
    },
    getAllLanguages() {
      this.getLanguages().then(languages => {
        this.languages = languages;
        this.defaultLanguageId = languages.filter(
          lang => lang.short_name === "en"
        )[0].uuid;
        if (!this.filters.language_uuid && languages.length) {
          this.filters.language_uuid = this.defaultLanguageId;
        }

        this.getAllTranslations(this.filters.page);
      }),
        () => {
          this.setSnackError("Noget gik galt");
        };
    }
  },
  created() {
    const filters = this.getUrlSearchFilters();
    this.filters = filters;
    this.getAllLanguages();
    this.getAreas();
  }
};
</script>
<style lang="scss"></style>
