<template>
  <v-dialog
    v-model="showing"
    max-width="600px"
    content-class="languages-wrapper"
  >
    <v-card>
      <v-card-title>
        <div class="d-flex align-center">
          <div class="headline">
            ADD A NEW KEY
          </div>
          <v-icon class="cursor-pointer" @click="$emit('cancel', true)"
            >fal fa-times
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="mt-5">
          <div>
            <span class="form-label">
              Key
            </span>
            <v-text-field
              v-model="form.key"
              name="Key"
              outlined
              v-validate="'required'"
              :error-messages="errors.collect('Key')"
            ></v-text-field>
          </div>
          <div class="mt-5">
            <span class="form-label">
              Areas
            </span>
            <v-select
              :items="itemsWithAddNew"
              v-model="form.translation_areas"
              item-text="name"
              item-value="uuid"
              multiple
              max-height="auto"
              :menu-props="{
                bottom: true,
                offsetY: true
              }"
            >
              <template #selection="{ item }">
                <v-chip
                  color="secondary"
                  text-color="primary"
                  class="chip-wrap"
                  >{{ item.name }}</v-chip
                >
              </template>
              <template slot="item" slot-scope="data">
                <template v-if="data.item === 'add_area' && !addingItem">
                  <v-list-item-action>
                    <v-btn color="primary" dark icon>
                      <v-icon>add</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-content
                    @click.stop="enterNewItem"
                    class="primary--text"
                  >
                    Add a new area...
                  </v-list-item-content>
                </template>
                <template v-if="data.item === 'add_area' && addingItem">
                  <v-layout
                    row
                    wrap
                    class="white p-3 d-flex align-end"
                    @click.stop=""
                  >
                    <v-text-field
                      ref="itemToAdd"
                      @click.stop=""
                      v-model="itemToAdd"
                      placeholder="Enter New Area"
                      hide-details
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      dark
                      @click.stop="addNewItem"
                      class="mx-3"
                    >
                      Add
                    </v-btn>
                    <v-btn outlined @click.stop="clearNewItem">
                      Cancel
                    </v-btn>
                  </v-layout>
                </template>
                <template v-if="data.item !== 'add_area'">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>
                        {{
                          form.translation_areas &&
                          form.translation_areas.includes(data.item.uuid)
                            ? "fas fa-check-square"
                            : "far fa-square"
                        }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
            </v-select>
          </div>
          <div>
            <span class="form-label">
              <country-flag country="us" size="small" />
              Default
            </span>
            <v-textarea
              v-model="form.value"
              class="mt-5 textarea-wrap"
              name="Default"
              v-validate="'required|min:3'"
              :error-messages="errors.collect('Default')"
            ></v-textarea>
          </div>
          <div>
            <span class="form-label">
              <country-flag country="dk" size="small" />
              Danish
            </span>
            <v-textarea
              v-model="extraTranslation"
              class="mt-5 textarea-wrap"
            ></v-textarea>
          </div>
          <v-btn
            color="primary"
            :disabled="!isFormFilled"
            class="d-flex ml-auto text-transform-uppercase mt-5"
            @click="addTranslationKey"
            >create
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      required: true
    },
    translationAreas: {
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    form: {
      key: "",
      value: ""
    },
    addingItem: false,
    itemToAdd: "",
    extraTranslation: null
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    isFormFilled() {
      return !!this.form.key.length && !!this.form.value.length;
    },
    itemsWithAddNew() {
      return this.translationAreas.concat([{ divider: true }, "add_area"]);
    }
  },
  methods: {
    ...mapActions("translations", [
      "createTranslationKey",
      "getTranslationAreas",
      "createTranslationArea"
    ]),
    cleanForm() {
      this.form = {
        key: "",
        translation_areas: [],
        value: ""
      };
    },
    enterNewItem() {
      this.addingItem = true;
      setTimeout(() => {
        this.$refs.itemToAdd.$refs.input.focus();
      }, 5);
    },
    addNewItem() {
      if (!this.translationAreas.includes(this.itemToAdd)) {
        this.createTranslationArea({ name: this.itemToAdd }).then(area => {
          this.translationAreas.push(area);
        });
      }
      this.clearNewItem();
    },
    clearNewItem() {
      this.itemToAdd = "";
      this.addingItem = false;
    },
    addTranslationKey() {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.extraTranslation) {
            this.form.translations = [
              {
                language_uuid: this.languages.find(
                  lang => lang.short_name === "dk"
                ).uuid,
                value: this.extraTranslation
              }
            ];
          }
          this.createTranslationKey({ ...this.form }).then(() => {
            this.setSnackSuccess("Gemt");
            this.$emit("success");
            this.cleanForm();
          }),
            () => {
              this.setSnackError("Noget gik galt");
            };
        }
      });
    }
  }
};
</script>
