<template>
  <v-select
    :placeholder="placeholder"
    :items="items"
    v-model="selected"
    :return-object="false"
    item-text="name"
    item-value="uuid"
    :multiple="multiple"
    :clearable="clearable"
    @clear="saveFilters"
    @change="saveFilters"
    :menu-props="{
      contentClass: 'custom-selection',
      bottom: true,
      offsetY: true
    }"
    class="multiple-select"
    :style="`width: ${width}px`"
    :class="selected ? 'highlight' : ''"
  >
    <template v-slot:selection="{ index }">
      <span v-if="index === 0">
        <span v-if="multiple" class="selected-icon">{{ selected.length }}</span>
        {{ placeholder }}
      </span>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false
    },
    width: {
      type: String,
      required: false,
      default: "150"
    },
    label: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: false
    }
  },
  data: () => ({
    selected: []
  }),
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.selected = newVal;
      }
    }
  },
  methods: {
    saveFilters() {
      this.$emit("save", this.selected);
    }
  }
};
</script>
<style scoped lang="scss">
.highlight {
  background-color: var(--v-secondary-base) !important;
  border-radius: 5px;
}
.selected-icon {
  display: inline-block;
  background-color: var(--v-accent-base) !important;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  margin-right: 5px;
}
</style>
