export default {
  methods: {
    copyCode(code) {
      navigator.clipboard.writeText(code);
      this.copyText = "Copied!";
      setTimeout(() => {
        this.copyText = "Copy";
      }, 1000);
    }
  }
};
