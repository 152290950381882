<template>
  <v-dialog
    v-model="showing"
    max-width="600px"
    content-class="languages-wrapper"
  >
    <v-card>
      <v-card-title>
        <div class="d-flex align-center">
          <div class="headline">
            Languages
          </div>
          <v-icon class="cursor-pointer" @click="$emit('cancel', true)"
            >fal fa-times
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-center language-form-wrap">
          <v-text-field
            v-model="form.name"
            placeholder="Language Name"
            class="mr-5 w-50"
            name="Name"
            v-validate="'required'"
            :error-messages="errors.collect('Name')"
          ></v-text-field>
          <v-text-field
            v-model="form.short_name"
            placeholder="Short name"
            class="mr-5 form-short-name"
            name="Short name"
            v-validate="'required'"
            :error-messages="errors.collect('Short name')"
          ></v-text-field>
          <v-btn
            color="primary"
            class="d-flex ml-auto text-transform-uppercase"
            @click="addLanguage"
            >Add Language
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="clonedLanguages"
          v-if="clonedLanguages.length"
          :disable-sort="true"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.uuid">
                <td>
                  <div class="d-flex align-center">
                    <country-flag
                      :country="
                        item.short_name === 'en' ? 'us' : item.short_name
                      "
                      size="small"
                    />
                    <span class="pl-3">{{ item.name }}</span>
                  </div>
                </td>
                <td>{{ item.short_name }}</td>
                <td>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <div class="pt-5" v-on="on">
                        <v-switch
                          v-model="item.status"
                          inset
                          :ripple="false"
                          v-on="on"
                          @change="toggleStatus(item)"
                        ></v-switch>
                      </div>
                    </template>
                    <span>{{ item.status ? "Published" : "Unpublished" }}</span>
                  </v-tooltip>
                </td>
                <td class="text-center">
                  <div v-if="item.untranslated.length > 0">
                    {{ item.untranslated.length }}
                    <v-icon
                      small
                      class="cursor-pointer ml-4"
                      @click="changeRoute(item.uuid)"
                      >fa fa-external-link-alt
                    </v-icon>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    form: {
      name: "",
      short_name: "",
      status: false
    },
    headers: [
      { text: "Name", value: "name" },
      { text: "Short name", value: "short_name" },
      { text: "Status", value: "status" },
      { text: "Untranslated", value: "untranslated" }
    ]
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    clonedLanguages() {
      return [...this.languages];
    }
  },
  methods: {
    ...mapActions("translations", ["createLanguage", "updateLanguage"]),
    cleanForm() {
      this.form = {
        name: "",
        short_name: "",
        status: false
      };
    },
    addLanguage() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.createLanguage({ ...this.form }).then(response => {
            this.setSnackSuccess("Gemt");
            this.cleanForm();
            this.clonedLanguages.push(response);
            this.$emit("success");
          }),
            () => {
              this.setSnackError("Noget gik galt");
            };
        }
      });
    },

    toggleStatus(lang) {
      const params = {
        uuid: lang.uuid,
        status: lang.status
      };
      this.updateLanguage(params).then(() => {
        this.setSnackSuccess("Gemt");
        this.$emit("success");
      }),
        () => {
          this.setSnackError("Noget gik galt");
        };
    },
    changeRoute(id) {
      const filters = {
        untranslated_only: true,
        page: 1,
        language_uuid: id
      };
      this.$emit("change", filters);
      this.$emit("cancel", true);
    }
  }
};
</script>
<style lang="scss" scoped></style>
