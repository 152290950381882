var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px","content-class":"languages-wrapper"},model:{value:(_vm.showing),callback:function ($$v) {_vm.showing=$$v},expression:"showing"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"headline"},[_vm._v(" KEY OVERVIEW ")]),_c('v-icon',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$emit('cancel', true)}}},[_vm._v("fal fa-times ")])],1)]),_c('v-card-text',[_c('div',{staticClass:"mt-5"},[_c('div',[_c('span',{staticClass:"form-label"},[_vm._v(" Key ")]),_c('v-text-field',{attrs:{"name":"Key","readonly":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.copyCode(_vm.selectedKey.translationKey.key)}}},on),[_vm._v("fal fa-copy")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.copyText))])])]},proxy:true}]),model:{value:(_vm.selectedKey.translationKey.key),callback:function ($$v) {_vm.$set(_vm.selectedKey.translationKey, "key", $$v)},expression:"selectedKey.translationKey.key"}})],1),_c('div',[_c('span',{staticClass:"form-label"},[_vm._v(" Code ")]),_c('v-text-field',{attrs:{"value":("{{ $t('" + (_vm.selectedKey.translationKey.key) + "') }}"),"name":"Code","readonly":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){_vm.copyCode(
                        ("{{ $t('" + (_vm.selectedKey.translationKey.key) + "') }}")
                      )}}},on),[_vm._v("fal fa-copy")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.copyText))])])]},proxy:true}])})],1),_c('div',{staticClass:"mt-5"},[_c('span',{staticClass:"form-label"},[_vm._v(" Areas ")]),_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.selectedKey.translationKey.translation_areas),function(area){return _c('v-chip',{key:area.uuid,staticClass:"chip-wrap",attrs:{"color":"secondary","text-color":"primary"}},[_vm._v(" "+_vm._s(area.name)+" ")])}),1)],1),(_vm.selectedKey.translationKey.translations.length)?_c('div',{staticClass:"mt-5"},[_c('div',[_vm._v("Translations")]),_vm._l((_vm.selectedKey.translationKey.translations),function(translation){return _c('div',{key:translation.uuid},[_c('span',{staticClass:"form-label"},[_c('country-flag',{attrs:{"country":translation.language.short_name === 'en'
                    ? 'us'
                    : translation.language.short_name,"size":"small"}}),_vm._v(" "+_vm._s(translation.language.name)+" ")],1),_c('v-text-field',{staticClass:"mt-5",attrs:{"readonly":""},model:{value:(translation.value),callback:function ($$v) {_vm.$set(translation, "value", $$v)},expression:"translation.value"}})],1)})],2):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }