<template>
  <div v-if="items.length > 0">
    <v-data-table
      :headers="[
        { text: 'Key & Code', align: 'start' },
        { text: 'Default' },
        { text: 'Translation', align: isDefaultLang ? ' d-none' : '' },
        { text: 'Area' },
        { text: 'Action' }
      ]"
      :items="items"
      hide-default-footer
      :disable-sort="true"
      :items-per-page="10000"
      class="translation-table-wrap"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="key in items" :key="key.uuid">
            <td class="pb-10">
              <v-text-field
                v-model="key.translation_key_key"
                name="Code"
                label="Key"

                readonly
                hide-details
                class="pb-5"
                outlined
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                        @click="copyCode(key.translation_key_key)"
                        >fal fa-copy</v-icon
                      >
                    </template>
                    <span>{{ copyText }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-text-field
                :value="`{{ $t('${key.translation_key_key}') }}`"
                label="Code"
                name="Code"
                readonly
                class="pb-5"
                hide-details
                outlined
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                        @click="
                          copyCode(`{{ $t('${key.translation_key_key}') }}`)
                        "
                        >fal fa-copy</v-icon
                      >
                    </template>
                    <span>{{ copyText }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>

              <v-text-field
                :value="`<p>${key.translation_key_key}</p>`"
                label="HTML"
                hide-details
                readonly
                outlined
              >
                <template v-slot:append>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        v-on="on"
                        @click="copyCode(`<p>${key.translation_key_key}</p>`)"
                        >fal fa-copy</v-icon
                      >
                    </template>
                    <span>{{ copyText }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </td>
            <td class="pt-10 pb-3 text-right">
              <v-textarea
                outlined
                name="input-7-4"
                :disabled="!isDefaultLang"
                :class="
                  !isDefaultLang
                    ? 'disabled-textarea translation-area'
                    : 'translation-area'
                "
                label="English"
                v-model="key.default_value"
                @input="rowId = key.translation_key_uuid"
              ></v-textarea>
              <v-btn
                color="primary"
                :style="{
                  visibility: isBtnEnabled(
                    isDefaultLang,
                    rowId,
                    key.translation_key_uuid
                  )
                    ? 'visible'
                    : 'hidden'
                }"
                @click="
                  editTranslation(key.translation_key_uuid, key.default_value)
                "
                >Save</v-btn
              >
            </td>
            <td class="pt-10 pb-3  text-right" v-if="!isDefaultLang">
              <v-textarea
                outlined
                name="input-7-4"
                class="translation-area"
                :label="lang.name"
                v-model="key.translated_value"
                @input="rowId = key.translation_key_uuid"
              ></v-textarea>
              <v-btn
                color="primary"
                :style="{
                  visibility: isBtnEnabled(
                    !isDefaultLang,
                    rowId,
                    key.translation_key_uuid
                  )
                    ? 'visible'
                    : 'hidden'
                }"
                @click="
                  editTranslation(
                    key.translation_key_uuid,
                    key.translated_value
                  )
                "
                >Save</v-btn
              >
            </td>
            <td class="areas-column">
              <v-select
                v-model="key.translation_areas"
                :items="translationAreas"
                item-text="name"
                item-value="uuid"
                outlined
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
                deletable-chips
                :return-object="true"
                multiple
                @change="addArea(key, $event)"
              >
                <template #selection="{ item }">
                  <v-chip
                    close
                    color="secondary"
                    text-color="primary"
                    close-icon="close"
                    @input="removeArea(key, item)"
                    class="chip-wrap"
                    @click:close="removeArea(key, item)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
            </td>
            <td class="action-column">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    outlined
                    :disabled="!key.default_value"
                    color="primary"
                    class="mr-0"
                    @click="getTranslationData(key)"
                  >
                    <v-icon small>fal fa-eye</v-icon>
                  </v-btn>
                </template>
                <span>Overview</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currPage"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
    <key-overview-dialog
      v-if="keyInfo"
      @cancel="keyOverviewDialog = false"
      v-model="keyOverviewDialog"
      :selectedKey="keyInfo"
    />
  </div>
</template>

<script>
import KeyOverviewDialog from "@/components/moderator/dialogs/KeyOverviewDialog";
import copy_to_clipboard from "@/helpers/mixins/copy_to_clipboard";
import { mapActions } from "vuex";

export default {
  components: { KeyOverviewDialog },
  mixins: [copy_to_clipboard],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    translationAreas: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    page: {
      type: Number
    },
    defaultLanguageId: {
      type: String
    },
    total: {
      type: Number
    },
    lang: {
      type: Object
    }
  },
  data: () => ({
    keyOverviewDialog: false,
    currPage: 1,
    copyText: "Copy",
    keyInfo: null,
    rowId: null
  }),
  computed: {
    isDefaultLang() {
      return this.lang.short_name === "en";
    }
  },
  watch: {
    items() {
      this.currPage = this.page;
    }
  },
  methods: {
    ...mapActions("translations", [
      "updateTranslation",
      "removeTranslationAreas",
      "addTranslationAreas",
      "getTranslation"
    ]),
    getTranslationData(key) {
      const params = {
        language_uuid: this.defaultLanguageId,
        translation_key_uuid: key.translation_key_uuid
      };
      this.getTranslation(params).then(response => {
        this.keyInfo = response;
        this.keyOverviewDialog = true;
      }),
        () => {
          this.setSnackError("Noget gik galt");
        };
    },
    removeArea(key, item) {
      key.translation_areas = key.translation_areas.filter(
        area => area.uuid !== item.uuid
      );
      const params = {
        uuid: key.translation_key_uuid,
        translation_areas: item.uuid
      };
      this.removeTranslationAreas(params).then(() => {
        this.setSnackSuccess("Gemt");
      }),
        () => {
          this.setSnackError("Noget gik galt");
        };
    },
    editTranslation(uuid, value) {
      const params = {
        value: value,
        language_uuid: this.lang.uuid,
        translation_key_uuid: uuid
      };
      this.updateTranslation(params).then(() => {
        this.setSnackSuccess("Gemt");
        this.rowId = null;
      }),
        () => {
          this.setSnackError("Noget gik galt");
        };
      this.isBtnEnabled();
    },
    isBtnEnabled(value, rowId, keyId) {
      if (!rowId) {
        return false;
      }
      return value && rowId === keyId;
    },
    addArea(key, items) {
      const areas = items.map(item => item.uuid);
      const params = {
        uuid: key.translation_key_uuid,
        translation_areas: areas
      };
      this.addTranslationAreas(params).then(() => {
        this.setSnackSuccess("Gemt");
      }),
        () => {
          this.setSnackError("Noget gik galt");
        };
    },
    handlePageChange(value) {
      this.scrollToTop();
      this.$emit("changePage", value);
    }
  }
};
</script>
