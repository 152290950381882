<template>
  <div class="filters-wrap">
    <div class="filter-selects-wrap">
      <custom-select
        placeholder="Language"
        @save="saveFilters({ language_uuid: $event })"
        :items="languages"
        v-model="filters.language_uuid"
        width="160"
        class="mr-2"
      />
      <custom-select
        placeholder="Area"
        @save="saveFilters({ translation_areas: $event })"
        multiple
        clearable
        :items="translationAreas"
        v-model="filters.translation_areas"
        width="160"
        class="mr-2"
      />
      <v-checkbox
        v-model="filters.untranslated_only"
        label="Untranslated"
        @change="saveFilters({ untranslated_only: $event })"
        color="primary"
        hide-details
        class="label-size"
      ></v-checkbox>
    </div>
    <v-text-field
      v-model="search"
      append-icon="search"
      placeholder="Search for key word or phase"
      clearable
      class="custom-search"
    ></v-text-field>
  </div>
</template>
<script>
import CustomSelect from "@/components/common/filters/CustomSelect";
import _ from "lodash";

export default {
  components: {
    CustomSelect
  },
  props: {
    filters: {
      required: true,
      type: Object
    },
    languages: {
      required: true,
      type: Array
    },
    translationAreas: {
      required: true,
      type: Array
    }
  },
  data: () => ({
    search: ""
  }),
  watch: {
    search: {
      handler: _.debounce(function() {
        this.saveFilters({ search: this.search });
      }, 500)
    }
  },
  computed: {
    internalFilters() {
      return this.cloneDeep(this.filters);
    }
  },
  methods: {
    saveFilters(event) {
      this.$emit("save", { ...this.filters, ...event });
    }
  }
};
</script>
<style lang="scss" scoped>
.filters-wrap {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-selects-wrap {
  display: flex;
}
</style>
