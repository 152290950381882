var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0)?_c('div',[_c('v-data-table',{staticClass:"translation-table-wrap",attrs:{"headers":[
      { text: 'Key & Code', align: 'start' },
      { text: 'Default' },
      { text: 'Translation', align: _vm.isDefaultLang ? ' d-none' : '' },
      { text: 'Area' },
      { text: 'Action' }
    ],"items":_vm.items,"hide-default-footer":"","disable-sort":true,"items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('tbody',_vm._l((items),function(key){return _c('tr',{key:key.uuid},[_c('td',{staticClass:"pb-10"},[_c('v-text-field',{staticClass:"pb-5",attrs:{"name":"Code","label":"Key","readonly":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.copyCode(key.translation_key_key)}}},on),[_vm._v("fal fa-copy")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copyText))])])]},proxy:true}],null,true),model:{value:(key.translation_key_key),callback:function ($$v) {_vm.$set(key, "translation_key_key", $$v)},expression:"key.translation_key_key"}}),_c('v-text-field',{staticClass:"pb-5",attrs:{"value":("{{ $t('" + (key.translation_key_key) + "') }}"),"label":"Code","name":"Code","readonly":"","hide-details":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){_vm.copyCode(("{{ $t('" + (key.translation_key_key) + "') }}"))}}},on),[_vm._v("fal fa-copy")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copyText))])])]},proxy:true}],null,true)}),_c('v-text-field',{attrs:{"value":("<p>" + (key.translation_key_key) + "</p>"),"label":"HTML","hide-details":"","readonly":"","outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.copyCode(("<p>" + (key.translation_key_key) + "</p>"))}}},on),[_vm._v("fal fa-copy")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copyText))])])]},proxy:true}],null,true)})],1),_c('td',{staticClass:"pt-10 pb-3 text-right"},[_c('v-textarea',{class:!_vm.isDefaultLang
                  ? 'disabled-textarea translation-area'
                  : 'translation-area',attrs:{"outlined":"","name":"input-7-4","disabled":!_vm.isDefaultLang,"label":"English"},on:{"input":function($event){_vm.rowId = key.translation_key_uuid}},model:{value:(key.default_value),callback:function ($$v) {_vm.$set(key, "default_value", $$v)},expression:"key.default_value"}}),_c('v-btn',{style:({
                visibility: _vm.isBtnEnabled(
                  _vm.isDefaultLang,
                  _vm.rowId,
                  key.translation_key_uuid
                )
                  ? 'visible'
                  : 'hidden'
              }),attrs:{"color":"primary"},on:{"click":function($event){return _vm.editTranslation(key.translation_key_uuid, key.default_value)}}},[_vm._v("Save")])],1),(!_vm.isDefaultLang)?_c('td',{staticClass:"pt-10 pb-3  text-right"},[_c('v-textarea',{staticClass:"translation-area",attrs:{"outlined":"","name":"input-7-4","label":_vm.lang.name},on:{"input":function($event){_vm.rowId = key.translation_key_uuid}},model:{value:(key.translated_value),callback:function ($$v) {_vm.$set(key, "translated_value", $$v)},expression:"key.translated_value"}}),_c('v-btn',{style:({
                visibility: _vm.isBtnEnabled(
                  !_vm.isDefaultLang,
                  _vm.rowId,
                  key.translation_key_uuid
                )
                  ? 'visible'
                  : 'hidden'
              }),attrs:{"color":"primary"},on:{"click":function($event){return _vm.editTranslation(
                  key.translation_key_uuid,
                  key.translated_value
                )}}},[_vm._v("Save")])],1):_vm._e(),_c('td',{staticClass:"areas-column"},[_c('v-select',{attrs:{"items":_vm.translationAreas,"item-text":"name","item-value":"uuid","outlined":"","menu-props":{
                bottom: true,
                offsetY: true
              },"deletable-chips":"","return-object":true,"multiple":""},on:{"change":function($event){return _vm.addArea(key, $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{staticClass:"chip-wrap",attrs:{"close":"","color":"secondary","text-color":"primary","close-icon":"close"},on:{"input":function($event){return _vm.removeArea(key, item)},"click:close":function($event){return _vm.removeArea(key, item)}}},[_vm._v(_vm._s(item.name))])]}}],null,true),model:{value:(key.translation_areas),callback:function ($$v) {_vm.$set(key, "translation_areas", $$v)},expression:"key.translation_areas"}})],1),_c('td',{staticClass:"action-column"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0",attrs:{"icon":"","outlined":"","disabled":!key.default_value,"color":"primary"},on:{"click":function($event){return _vm.getTranslationData(key)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Overview")])])],1)])}),0)]}}],null,false,1380837326)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1),_c('v-pagination',{staticClass:"my-2",attrs:{"length":_vm.total,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.currPage),callback:function ($$v) {_vm.currPage=$$v},expression:"currPage"}}),(_vm.keyInfo)?_c('key-overview-dialog',{attrs:{"selectedKey":_vm.keyInfo},on:{"cancel":function($event){_vm.keyOverviewDialog = false}},model:{value:(_vm.keyOverviewDialog),callback:function ($$v) {_vm.keyOverviewDialog=$$v},expression:"keyOverviewDialog"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }